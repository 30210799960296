body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-layout {
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
  text-decoration: none;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  font-weight: 400;
  color: #333;
  background-color: #FFF;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  position: relative;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
