.order-details-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.order-details {
    height: 100%;
    margin: 0 auto;
    padding: 28px 0 0;
    width: 80%;
    font-family: 'Mariad Pro', 'Helvetica', sans-serif;
}

.order-details__input {
    width: 100%;
    max-width: 870px;
    min-height: 41px;
    margin-bottom: 80px;
    padding: 7px 12px 9px;
    font-size: 24px;
    border: 1px solid #333;
    border-radius: 6px;
}

.order-details__content {
    display: flex;
    justify-content: space-between;
}

.order-list {
    min-width: 460px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.order-list__item {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
}

.order-list__title {
    margin: 0;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    color: #737373;
}

.order-list__description {
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 18px;
    line-height: 1.2;
    align-self: flex-end;
}

.status-list {
    min-width: 310px;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.status-list__item:last-of-type .status-list__line {
    display: none;
}

.status-list__info {
    display: flex;
}

.status-list__status {
    min-width: 138px;
    margin-right: 21px;
    padding: 7px;
    border: 1px solid #FDCC00;
    border-radius: 6px;
    font-size: 20px;
    line-height: 1.2;
    color: #737373;
    text-align: center;
    text-transform: uppercase;
}

.status-list__status_active {
    background-color: #FDCC00;
    font-weight: 700;
    color: #333;
}

.status-list__description {
    margin: 0;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    color: #737373;
    align-self: center;
}

.status-list__description_active {
    color: #333;
}

.status-list__line {
    width: 1px;
    margin-left: 70px;
    background-color: #333;
    height: 50px;
}