.language {
    position: absolute;
    left: 38px;
    bottom: 16px;
    display: flex;
    gap: 10px;
    z-index: 2;
}

.language__button {
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
    font-family: 'Mariad Pro', 'Helvetica', sans-serif;
    font-size: 21px;
    line-height: 1.2;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
}

.language__button_active {
    background: #FDCC00;
}
.Footer{
    height: 0;
}
.navigate-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 40px;
    position: relative;
    z-index: 1;
}

.nav-footer{
    display: flex;
}

.nav-footer a{
    display: flex;
    align-items: center;
    color: black;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    margin: 0 5px;
    transition: .3s;
}
.nav-footer a:hover{
    color: #FDCC00;
    transition: .3s;
}
.nav-footer a:nth-child(2):before{
    content: '⚫';
    font-size: 8px;
    margin-right: 10px;
    cursor: default;
}
.nav-footer a:nth-child(2)::after{
    content: '⚫';
    font-size: 8px;
    margin-left: 10px;
    cursor: default;
}
