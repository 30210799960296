.new-order-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.new-order {
    width: 50%;
    min-width: 701px;
    padding: 72px 0 0;
}

.new-order__title {
    margin: 0 0 36px;
    font-size: 42px;
    line-height: 1.18;
    font-weight: 400;
}

.new-order__form {
    display: flex;
    flex-direction: column;
}

.new-order__fieldset {
    margin-bottom: 27px;
}
.new-order__fieldse:last-of-type {
    margin-bottom: 33px;
}

.new-order__input {
    padding: 9px 12px 7px;
    font-size: 24px;
    line-height: 1.18;
}

.new-order__input::placeholder {
    color: #737373;
}

.new-order__button {
    min-width: 241px;
    padding: 15px 0 14px;
    align-self: flex-end;
    background: #333;
    font-family: 'Mariad Pro', 'Helvetica', sans-serif;
    font-size: 24px;
    line-height: 1.18;
    color: #FFF;
}