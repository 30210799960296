.not-found-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.not-found {
    height: 100%;
    padding-top: 155px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Mariad Pro', 'Helvetica', sans-serif;
}

.not-found__title {
    margin: 0 0 12px;
    z-index: 1;
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
}

.not-found__description {
    z-index: 1;
    font-size: 24px;
    line-height: 1.18;
}

.not-found__image {
    width: 646px;
    height: 496px;
    position: fixed;
    bottom: 0;
    left: 27%;
}