.order-search-popup__form {
    max-width: 254px;
    display: flex;
    flex-direction: column;
}

.order-search-popup__label {
    margin-bottom: 14px;
    font-size: 24px;
    line-height: 1.18;
    font-weight: 400;
    text-align: center;
}

.order-search-popup__fieldset {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: none;
}

.order-search-popup__input {
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 29px;
    padding: 9px 12px 7px;
    border: 1px solid #333333;
    border-radius: 6px;
    font-size: 24px;
    line-height: 1.18;
    color: #333;
}

.order-search-popup__input:focus {
    outline: none;
    border: 1px solid #333333;
}

.order-search-popup__button {
    min-height: 41px;
    padding: 6px;
    background: #FDCC00;
    font-family: 'Mariad Pro', 'Helvetica', sans-serif;
    font-size: 24px;
    line-height: 1.2;
}